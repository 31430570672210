.log-container {
  padding: 16px 32px;
}

.log-header {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 10px;
}

.log-entry-cell {
  width: 10%;
  vertical-align: top;
}
